import axios from "axios";
export const createUserAccountApi = {
  data() {
    return {
      loadingUser: false,
    };
  },
  methods: {
    async createUserAccountMethod() {
      this.loadingUser = true;
      const apiUrl =
        process.env.VUE_APP_AWS_CREATE_USER_ACCOUNT_REST_API_END_POINT;
      const apiKey = process.env.VUE_APP_AWS_CREATE_USER_ACCOUNT_REST_API_KEY;
      var inputsParams = this.createUserAccount;
      // console.log("inputsParams", inputsParams);
      try {
        const result = await axios.post(apiUrl, inputsParams, {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
        });
        // console.log("result of createUserAccountMethod", result);
        let successMsg = result.data;
        // console.log("successMsg", successMsg);
        if (successMsg && successMsg.Status === "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: successMsg.Status_Message,
          };
          return "SUCCESS";
        }

        let errorMsg = result.data.errorMessage;
        if (errorMsg) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: errorMsg,
          };
          return "ERROR";
        }
      } catch (error) {
        console.error("Error creating user:", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error,
        };
        return "ERROR";
      } finally {
        this.loadingUser = false;
      }
    },
  },
};
