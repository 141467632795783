import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "@/views/MainHomePages/LoginPage.vue";

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",

    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"
      ),

    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/ChildHomePages/Dashboard.vue"
          ),
      },
      {
        path: "/Sessions",
        name: "Sessions",
        component: () =>
          import(
            /* webpackChunkName: "Transactions" */ "../views/ChildHomePages/Sessions.vue"
          ),
      },
      {
        path: "/Customers",
        name: "Customers",
        component: () =>
          import(
            /* webpackChunkName: "Customers" */ "../views/ChildHomePages/Customers.vue"
          ),
      },
      {
        path: "/Utilization",
        name: "Utilization",
        component: () =>
          import(
            /* webpackChunkName: "Utilization" */ "../views/ChildHomePages/Utilization.vue"
          ),
      },
      {
        path: "/Realization",
        name: "Realization",
        component: () =>
          import(
            /* webpackChunkName: "Realization" */ "../views/ChildHomePages/Realization.vue"
          ),
      },
      {
        path: "/UserManagement",
        name: "UserManagement",
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "../views/ChildHomePages/UserManagement.vue"
          ),
      },
      {
        path: "/Centers",
        name: "Centers",
        component: () =>
          import(
            /* webpackChunkName: "Center" */ "../views/ChildHomePages/Centers.vue"
          ),
      },
      {
        path: "/Devices",
        name: "Devices",
        component: () =>
          import(
            /* webpackChunkName: "Devices" */ "../views/ChildHomePages/Devices.vue"
          ),
      },

      {
        path: "/Assets",
        name: "Assets",
        component: () =>
          import(
            /* webpackChunkName: "Assets" */ "../views/ChildHomePages/Assets.vue"
          ),
      },

      {
        path: "/PriceList",
        name: "PriceList",
        component: () =>
          import(
            /* webpackChunkName: "PriceList" */ "../views/ChildHomePages/PriceList.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
