import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    currentUserDetails: {},
  },

  getters: {
    get_user_email_id: (state) => state.EmailID,
    getCurrentUserDetails: (state) => state.currentUserDetails,
  },
  mutations: {
    SET_USER_EMAIL_ID(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, user) {
      state.currentUserDetails = user;
    },
  },
  actions: {},
  modules: {},
});

export default store;
