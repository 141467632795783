<template>
  <v-snackbar
    v-model="SnackBarComponent.SnackbarVmodel"
    :color="SnackBarComponent.SnackbarColor"
    location="top"
    :timeout="SnackBarComponent.timeout"
  >
    <div class="text-center fontVariant2">
      {{ SnackBarComponent.SnackbarText }}
    </div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },
};
</script>
